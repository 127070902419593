import { graphql } from '~/contentful/graphql';

export const FutureEventCard_ExternalEventFragment = graphql(`
  fragment FutureEventCard_ExternalEventFragment on ExternalEvent {
    name
    link
    logo {
      ...Image_AssetFragment
    }
    date
    startTime
    endTime
    timeZone
    eventType
  }
`);
