import cx from 'classnames';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { AnalyticsCard, AnalyticsModule, Event } from '~/ui/components/analytics';
import { FullBleedCTAScope, FullBleedCTATrigger } from '~/ui/components/fullBleedCta';
import { Image } from '~/ui/modules/image';
import { Breakpoint } from '~/ui/styles/grid';
import typographyStyles from '~/ui/styles/typography.module.scss';
import { getInternalLinkFromFragment } from '~/ui/systems/internalLink';
import { Link } from '~/v1/components/link/link';

import styles from './idea.module.scss';
import { IdeaCard_IdeaFragment } from './query';

export interface IdeaProps {
  data: FragmentType<typeof IdeaCard_IdeaFragment>;
}

export function IdeaCard({ data }: IdeaProps) {
  const idea = getFragmentData(IdeaCard_IdeaFragment, data);
  const { name, question, navigationImagePortrait } = idea;

  return (
    <FullBleedCTAScope>
      <article aria-label={question ?? undefined} className={styles.card}>
        <div className={styles.content}>
          {navigationImagePortrait && (
            <Image
              image={navigationImagePortrait}
              className={styles.image}
              aspectRatio="4/5"
              colSpan={{ [Breakpoint.SM]: 4, [Breakpoint.MD]: 3 }}
            />
          )}
          <div className={cx(typographyStyles.blockquote, styles.question)}>{question}</div>
          <div className={typographyStyles.bodySmall}>{name}</div>
        </div>

        <FullBleedCTATrigger>
          <Link
            href={getInternalLinkFromFragment(idea)}
            className={styles.link}
            event={{
              name: Event.CARD_CLICK,
              data: {
                cardModule: AnalyticsModule.RELATED,
                cardType: AnalyticsCard.IDEA,
                cardCTA: 'Explore the Idea',
                cardTitle: question,
              },
            }}
            withIcon
          >
            Explore the Idea
          </Link>
        </FullBleedCTATrigger>
      </article>
    </FullBleedCTAScope>
  );
}
