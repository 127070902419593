import { graphql } from '~/contentful/graphql';

export const IdeaCard_IdeaFragment = graphql(`
  fragment IdeaCard_IdeaFragment on Idea {
    navigationImagePortrait {
      ...Image_AssetFragment
    }
    question
    name
    ...InternalLink_EntryFragment
  }
`);
