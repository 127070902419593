'use client';

import cx from 'classnames';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { Typename } from '~/contentful/graphql/typename';
import { CardSize } from '~/ui/components/cards/base';
import { Column, Grid, type GridProps } from '~/ui/components/grid';
import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { AnnualReportCard } from '~/ui/modules/cards/annualReport';
import { ArticleCard } from '~/ui/modules/cards/article';
import { FeatureCard } from '~/ui/modules/cards/feature';
import { FutureEventCard } from '~/ui/modules/cards/futureEvent';
import { GrantStoryCard } from '~/ui/modules/cards/grantStory';
import { IdeaCard } from '~/ui/modules/cards/idea';
import { MmufCard } from '~/ui/modules/cards/mmuf';
import { ContentGrid_EntryFragment } from '~/ui/modules/contentGrid/query';
import { QuoteModule } from '~/ui/modules/quote';
import { Breakpoint } from '~/ui/styles/grid';
import styles from '~/v1/modules/grid/related/related.module.scss';

type ContentItem = FragmentType<typeof ContentGrid_EntryFragment>;

export interface ContentGridProps extends GridProps {
  title?: string;
  items: Array<ContentItem | React.ReactNode>;

  className?: string;
  titleClassName?: string;
}

export function ContentGrid({ title, items, titleClassName, ...props }: ContentGridProps) {
  const isSmallBreakpoint = useBreakpoint(Breakpoint.SM);

  return (
    <Grid {...props}>
      {title && (
        <Column sm={5} md={5} lg={5} offsetLeft={{ lg: 1 }}>
          <h3 className={cx(titleClassName ?? 'modulesTitleTypography', styles.title)}>{title}</h3>
        </Column>
      )}

      <Column sm={5} md={3} lg={3} offsetLeft={{ lg: 1 }}>
        <Grid>
          {items.map((item, index) => {
            const isContent = isContentItem(item);

            return isSmallBreakpoint ? (
              isContent ? (
                // render all cards on small breakpoint
                <Column
                  key={index}
                  sm={4}
                  className={styles.cardWrapper}
                  offsetLeft={{ sm: index % 2 === 0 ? 0 : 1 }}
                  offsetRight={{ sm: index % 2 === 0 ? 1 : 0 }}
                >
                  <div
                    className={cx(styles.cardInnerWrapper, {
                      [styles.cardInnerWrapperOdd]: index % 2 === 0,
                    })}
                  >
                    {renderItem(item)}
                  </div>
                </Column>
              ) : (
                <Column key={index} className={styles.cardWrapper} sm={5}>
                  <div className="overflowLeft" style={{ marginTop: '2.4rem' }}>
                    {item}
                  </div>
                </Column>
              )
            ) : index % 2 === 0 && isContent ? (
              // render even cards on left side for medium and > breakpoints
              <Column key={index} md={3} lg={3} className={styles.cardWrapper}>
                {renderItem(item)}
              </Column>
            ) : null;
          })}
        </Grid>
      </Column>

      {/* render odd cards on right side for medium and > breakpoints */}
      {!isSmallBreakpoint && (
        <Column md={3} lg={4} offsetLeft={{ md: 1, lg: 1 }}>
          <Grid>
            {items.map((item, index) => {
              const isArticle = isContentItem(item);

              if (!isArticle) {
                return (
                  <Column key={index} className={styles.cardWrapperOdd} md={4} lg={4}>
                    {item}
                  </Column>
                );
              }

              return index % 2 !== 0 && isArticle ? (
                <Column key={index} md={3} lg={3} className={styles.cardWrapperOdd}>
                  {renderItem(item)}
                </Column>
              ) : null;
            })}
          </Grid>
        </Column>
      )}
    </Grid>
  );
}

function isContentItem(contentItem: ContentItem | React.ReactNode): contentItem is ContentItem {
  return !!contentItem && typeof contentItem === 'object' && '__typename' in contentItem;
}

function renderItem(data: ContentItem) {
  const item = getFragmentData(ContentGrid_EntryFragment, data);

  switch (item.__typename) {
    case Typename.ARTICLE_PAGE:
      return <ArticleCard data={item} />;

    case Typename.GRANT_STORY:
      return <GrantStoryCard data={item} />;

    case Typename.IDEA_PAGE:
      return <IdeaCard data={item} />;

    case Typename.QUOTE:
      return <QuoteModule quote={item} isNarrow />;

    case Typename.EVENT_UPCOMING:
      return <FutureEventCard data={item} />;

    case Typename.FEATURE:
      return <FeatureCard data={item} />;

    case Typename.MMUF_PAGE:
      return <MmufCard data={item} />;

    case Typename.ANNUAL_REPORT_PAGE:
      return <AnnualReportCard data={item} size={CardSize.DEFAULT} />;
  }
}
