import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { flattenCollection } from '~/contentful/utils/collection';
import { AnalyticsCard, AnalyticsContent } from '~/ui/components/analytics';
import { BaseCard, type CardSize, type CommonCardProps } from '~/ui/components/cards/base';
import { getInternalLinkFromFragment } from '~/ui/systems/internalLink';

import { AnnualReportCard_AnnualReportFragment } from './query';

export interface AnnualReportCardProps extends CommonCardProps {
  data: FragmentType<typeof AnnualReportCard_AnnualReportFragment>;
  size: CardSize;
}

export function AnnualReportCard({ data, size, module, ...props }: AnnualReportCardProps) {
  const annualReport = getFragmentData(AnnualReportCard_AnnualReportFragment, data);
  const image = flattenCollection(annualReport.introImagesCollection)[0];

  return (
    <BaseCard
      title={`${annualReport.year} Annual Report`}
      cta="Read the report"
      href={getInternalLinkFromFragment(annualReport)}
      image={image}
      size={size}
      event={{
        cardType: AnalyticsCard.ANNUAL_REPORT,
        cardContentType: AnalyticsContent.ANNUAL_REPORT,
        cardModule: module,
      }}
      {...props}
    />
  );
}
