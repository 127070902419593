'use client';

import cx from 'classnames';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { Event, trackEvent } from '~/ui/components/analytics';
import { Image } from '~/ui/modules/image';
import { Breakpoint } from '~/ui/styles/grid';
import typographyStyles from '~/ui/styles/typography.module.scss';
import { AudioPlayer } from '~/v1/components/audioPlayer/audioPlayer';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Link } from '~/v1/components/link/link';
import { withLineBreaks } from '~/v1/components/quote/quote.utils';

import { QuoteModule_QuoteFragment } from './query';
import styles from './quote.module.scss';

interface QuoteModuleProps {
  quote: FragmentType<typeof QuoteModule_QuoteFragment>;
  isNarrow?: boolean;
  className?: string;
}

export function QuoteModule({ quote: parentQuote, isNarrow, className }: QuoteModuleProps) {
  const {
    quoteText,
    quoteImage,
    quoteAudio,
    link,
    linkText,
    attribution,
    twitterUsername,
    isTwitterImage,
  } = getFragmentData(QuoteModule_QuoteFragment, parentQuote);

  return (
    <div
      className={cx(styles.quote, className, {
        [styles.twitter]: isTwitterImage,
      })}
    >
      <div className={styles.quoteTopWrapper}>
        {quoteImage && (
          <div
            className={cx(styles.image, {
              [styles.imageNarrow]: isNarrow,
            })}
          >
            <Image image={quoteImage} aspectRatio="4/5" colSpan={{ [Breakpoint.SM]: 1 }} />
          </div>
        )}
        <div className="bodySmallTypography">
          {withLineBreaks(attribution ?? undefined)}
          {isTwitterImage && (
            <Link
              href={link ?? `https://twitter.com/${twitterUsername}`}
              className={styles.username}
              withIcon={IconType.Twitter}
            >
              @{twitterUsername}
            </Link>
          )}
        </div>
      </div>
      <div>
        {quoteAudio?.src && (
          <AudioPlayer
            componentNameAriaLabel="quote"
            src={quoteAudio.src}
            className={styles.listenButton}
            onPlay={() =>
              trackEvent(Event.QUOTE_AUDIO_PLAY, {
                quoteLink: link,
                quoteCopy: quoteText ?? '',
              })
            }
            onPause={() =>
              trackEvent(Event.QUOTE_AUDIO_PAUSE, {
                quoteLink: link,
                quoteCopy: quoteText ?? '',
              })
            }
          />
        )}
        <p className={cx(typographyStyles.blockquote, styles.quoteText)}>
          &ldquo;{quoteText}&rdquo;
        </p>
      </div>
      {!isTwitterImage && link && linkText && (
        <Link href={link} className={styles.link} withIcon>
          {linkText}
        </Link>
      )}
    </div>
  );
}
