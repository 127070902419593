import { graphql } from '~/contentful/graphql';

export const AnnualReportCard_AnnualReportFragment = graphql(`
  fragment AnnualReportCard_AnnualReportFragment on AnnualReport {
    year
    introImagesCollection(limit: 1) {
      items {
        ...Image_AssetFragment
      }
    }
    ...InternalLink_EntryFragment
  }
`);
