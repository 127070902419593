import { graphql } from '~/contentful/graphql';

export const MmufCard_MmufFragment = graphql(`
  fragment MmufCard_MmufFragment on Mmuf {
    title
    introDescription
    heroImage {
      ...Image_AssetFragment
    }
    ...InternalLink_EntryFragment
  }
`);
