import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { AnalyticsCard, AnalyticsContent } from '~/ui/components/analytics';
import { BaseCard, CardSize, type CommonCardProps } from '~/ui/components/cards/base';
import { getInternalLinkFromFragment } from '~/ui/systems/internalLink';

import { FeatureCard_FeatureFragment } from './query';

export interface FeatureCardProps extends CommonCardProps {
  data: FragmentType<typeof FeatureCard_FeatureFragment>;
}

export function FeatureCard({ data, module, ...props }: FeatureCardProps) {
  const feature = getFragmentData(FeatureCard_FeatureFragment, data);

  return (
    <BaseCard
      image={feature.heroImage ?? undefined}
      title={feature.name ?? ''}
      description={feature.dateString ?? undefined}
      cta="Explore the Special Feature"
      href={getInternalLinkFromFragment(feature)}
      event={{
        cardModule: module,
        cardType: AnalyticsCard.FEATURE,
        cardContentType: AnalyticsContent.FEATURE,
      }}
      size={CardSize.DEFAULT}
      {...props}
    />
  );
}
