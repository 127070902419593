import { graphql } from '~/contentful/graphql';

export const QuoteModule_QuoteFragment = graphql(`
  fragment QuoteModule_QuoteFragment on Quote {
    quoteText
    quoteImage {
      ...Image_AssetFragment
    }
    quoteAudio {
      src: url
    }
    link
    linkText
    attribution
    twitterUsername
    isTwitterImage
  }
`);
