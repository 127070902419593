import { graphql } from '~/contentful/graphql';

export const GrantStoryCard_GrantActivityArticleFragment = graphql(`
  fragment GrantStoryCard_GrantActivityArticleFragment on GrantActivityArticle {
    title
    leadImage {
      ...Image_AssetFragment
    }
    grant {
      organization {
        name
      }
    }
    granteeNameOverride
    ...InternalLink_EntryFragment
  }
`);
