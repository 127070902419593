import { graphql } from '~/contentful/graphql';

export const ContentGrid_EntryFragment = graphql(`
  fragment ContentGrid_EntryFragment on Entry {
    __typename
    ...ArticleCard_ArticleFragment
    ...GrantStoryCard_GrantActivityArticleFragment
    ...IdeaCard_IdeaFragment
    ...QuoteModule_QuoteFragment
    ...FutureEventCard_ExternalEventFragment
    ...FeatureCard_FeatureFragment
    ...MmufCard_MmufFragment
    ...AnnualReportCard_AnnualReportFragment
  }
`);
