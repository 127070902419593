import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { AnalyticsCard } from '~/ui/components/analytics';
import { type CommonCardProps } from '~/ui/components/cards/base';
import { LandingCard } from '~/ui/components/cards/landing';
import { getInternalLinkFromFragment } from '~/ui/systems/internalLink';

import { MmufCard_MmufFragment } from './query';

export interface MmufCardProps extends CommonCardProps {
  data: FragmentType<typeof MmufCard_MmufFragment>;
}

export function MmufCard({ data, module, ...props }: MmufCardProps) {
  const mmuf = getFragmentData(MmufCard_MmufFragment, data);

  return (
    <LandingCard
      title={mmuf.title ?? ''}
      href={getInternalLinkFromFragment(mmuf)}
      description={mmuf.introDescription ?? ''}
      image={mmuf.heroImage ?? undefined}
      cta="Learn about the MMUF"
      event={{
        cardType: AnalyticsCard.MMUF,
        cardModule: module,
      }}
      {...props}
    />
  );
}
