import { graphql } from '~/contentful/graphql';

export const FeatureCard_FeatureFragment = graphql(`
  fragment FeatureCard_FeatureFragment on Feature {
    name
    dateString: date
    heroImage {
      ...Image_AssetFragment
    }
    ...InternalLink_EntryFragment
  }
`);
