import cx from 'classnames';

import type { FragmentType } from '~/contentful/graphql';
import CardLogoIcon from '~/ui/assets/icons/card-logo.svg';
import {
  AnalyticsCard,
  type AnalyticsContent,
  AnalyticsModule,
  Event,
  trackEvent,
} from '~/ui/components/analytics';
import { FullBleedCTAScope, FullBleedCTATrigger } from '~/ui/components/fullBleedCta';
import { Image } from '~/ui/modules/image';
import { type Image_AssetFragment } from '~/ui/modules/image/query';
import { Link } from '~/v1/components/link/link';

import styles from './voices.module.scss';

export interface VoicesCardProps {
  title: string;
  href: string;
  isExternal: boolean;
  author?: {
    image?: FragmentType<typeof Image_AssetFragment>;
    name: string;
    title: string;
    organization: string;
  };
  type?: AnalyticsContent;
  className?: string;
}

export function VoicesCard({ title, href, isExternal, author, type, className }: VoicesCardProps) {
  return (
    <FullBleedCTAScope>
      <article aria-label={title} className={cx(styles.card, className)}>
        <div className={cx(styles.eyebrow, 'bodySmallTypography')}>Voice</div>
        <div className={cx(styles.title, 'bodyLargeTypography truncate-6')}>{title}</div>
        <FullBleedCTATrigger>
          <Link
            href={href}
            withIcon
            onClick={() =>
              trackEvent(Event.CARD_CLICK, {
                cardModule: AnalyticsModule.RELATED,
                cardType: AnalyticsCard.VOICES,
                cardContentType: type,
                cardCTA: 'Read the essay',
                cardTitle: title,
              })
            }
            className={styles.link}
          >
            Read the essay
          </Link>
        </FullBleedCTATrigger>
        {author && (
          <div className={cx(styles.author, 'captionTypography')}>
            {author.image ? (
              <Image image={author.image} aspectRatio="4/5" className={styles.image} />
            ) : !isExternal ? (
              <div className={styles.imagePlaceholder}>
                <CardLogoIcon className={styles.imagePlaceholderIcon} />
              </div>
            ) : null}
            <div>{author.name}</div>
            <div>{author.title}</div>
            <div>{author.organization}</div>
          </div>
        )}
      </article>
    </FullBleedCTAScope>
  );
}
