import { graphql } from '~/contentful/graphql';

export const ArticleCard_ArticleFragment = graphql(`
  fragment ArticleCard_ArticleFragment on Article {
    type
    title
    eyebrow
    heroImage {
      ...Image_AssetFragment
    }
    publisherLogo {
      ...Image_AssetFragment
    }
    person {
      name
      departmentOrGrantMakingArea {
        ... on Department {
          name
        }
        ... on GrantmakingArea {
          name
        }
      }
      jobTitle
      image {
        ...Image_AssetFragment
      }
    }
    externalPerson {
      name
      organization
      jobTitle
      image {
        ...Image_AssetFragment
      }
    }
    externalNewsUrl
    date
    ...InternalLink_EntryFragment
  }
`);
