import cx from 'classnames';

import { BaseCard, type BaseCardProps, CardSize } from '~/ui/components/cards/base';

import styles from './landing.module.scss';

export interface LandingCardProps extends Omit<BaseCardProps, 'truncateDescription' | 'size'> {}

export function LandingCard({ className, ...props }: LandingCardProps) {
  return (
    <BaseCard
      {...props}
      className={cx(styles.landingCard, className)}
      truncateDescription
      size={CardSize.DEFAULT}
    />
  );
}
