import { type Optional, filterNonOptional } from './optional';

/**
 * The definition of a collection of items from Contentful, which has the
 * following structure:
 * ```
 *   collection {
 *     items: []
 *   }
 * ```
 *
 * @param __typename The Contentful type name of the elements in `items`.
 * @param items The array of elements of type {@link T} or `null`.
 */
export type Collection<T> = { __typename: string; items: Array<Optional<T>> };

/**
 * Flattens the Contentful collection structure into the `items` array.
 *
 * @param collection An optional object containing an `items` array that will be
 *   flattened into the `items` array itself. All `undefined` or `null` elements
 *   are filtered out.
 * @returns The `items` array or an empty array if `collection` is `undefined`
 *   or `null`.
 */
export function flattenCollection<T>(collection: Optional<Collection<T>>): Array<T> {
  return filterNonOptional(collection?.items ?? null);
}

/**
 * Flattens the Contentful collection structure into `items` array and asserts
 * that it is a singleton list.
 *
 * @param collection An optional object containing an `items` array that will be
 *   flattened into the `items` array itself. All `undefined` or `null` elements
 *   are filtered out.
 * @returns The single element in the `items` array.
 * @throws {Error} If `items` is not a singleton list, i.e., empty or contains
 *   more than a single element.
 */
export function flattenSingletonCollection<T>(collection: Optional<Collection<T>>): T {
  const items = flattenCollection(collection);
  if (items.length > 1) {
    console.warn(
      `Multiple entries found for type '${collection?.__typename}', selecting only the first one`,
    );
  }

  const item = items[0];
  if (!item) {
    throw new Error(`No entry found for type '${collection?.__typename}'`);
  }

  return item;
}
