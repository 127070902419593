import NextLink from 'next/link';

import cx from 'classnames';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { AnalyticsCard, AnalyticsContent, AnalyticsModule, Event } from '~/ui/components/analytics';
import { Button, ButtonSize, ButtonVariant } from '~/ui/components/button';
import { FullBleedCTAScope, FullBleedCTATrigger } from '~/ui/components/fullBleedCta';
import { Image } from '~/ui/modules/image';
import { Breakpoint } from '~/ui/styles/grid';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';

import styles from './futureEvent.module.scss';
import { FutureEventCard_ExternalEventFragment } from './query';

export interface FutureEventCardProps {
  data: FragmentType<typeof FutureEventCard_ExternalEventFragment>;
  showEventsPageLink?: boolean;
}

export function FutureEventCard({ data, showEventsPageLink }: FutureEventCardProps) {
  const { name, link, logo, date, startTime, endTime, timeZone, eventType } = getFragmentData(
    FutureEventCard_ExternalEventFragment,
    data,
  );

  const timeZoneId = determineTimeZoneId(timeZone ?? '');
  const parsedDate = parseISO(date);
  const formattedEventDate = zonedTimeToUtc(parsedDate, timeZoneId).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: '2-digit',
  });
  let timeframe: string | undefined = undefined;
  if (startTime && endTime) {
    const formattedDate = format(parsedDate, 'yyyy-MM-dd');
    const startDate = zonedTimeToUtc(new Date(`${formattedDate} ${startTime}`), timeZoneId);
    const endDate = zonedTimeToUtc(new Date(`${formattedDate} ${endTime}`), timeZoneId);
    const timeFormat = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'shortGeneric',
    });
    timeframe = timeFormat.formatRange(startDate, endDate);
  }

  return (
    <FullBleedCTAScope>
      <article aria-label={name ?? undefined}>
        {logo && (
          <Image
            image={logo}
            className={styles.smallImage}
            colSpan={{ [Breakpoint.SM]: 1 }}
            aspectRatio="1/1"
          />
        )}

        <div className={styles.content}>
          <div className={cx('bodySmallTypography', styles.eyebrow)}>
            {eventType === 'Event' ? 'Event' : 'Virtual event'}
          </div>
          <div className="modulesTitleTypography truncate-4">{name}</div>

          <div className={styles.dateWrapper}>
            <div className="bodySmallTypography">{formattedEventDate}</div>
            <div className="bodySmallTypography">{timeframe}</div>
          </div>

          <div className={cx(styles.ctaWrapper, 'expand')}>
            <FullBleedCTATrigger>
              <Link
                href={link ?? ''}
                className={styles.detailsLink}
                event={{
                  name: Event.CARD_CLICK,
                  data: {
                    cardModule: showEventsPageLink
                      ? AnalyticsModule.RELATED
                      : AnalyticsModule.UPCOMING_EVENTS,
                    cardType: AnalyticsCard.EVENT,
                    cardContentType: AnalyticsContent.FUTURE_EVENT,
                    cardCTA: 'See details',
                    cardTitle: name,
                  },
                }}
                withIcon
              >
                See details
              </Link>
            </FullBleedCTATrigger>

            {showEventsPageLink && (
              <Button
                size={ButtonSize.TINY}
                variant={ButtonVariant.SECONDARY_TRANSPARENT}
                render={<NextLink href={Route.EVENTS} />}
                className={styles.eventPageLink}
              >
                See all events
              </Button>
            )}
          </div>
        </div>
      </article>
    </FullBleedCTAScope>
  );
}

function determineTimeZoneId(timeZone: string): string {
  switch (timeZone) {
    case 'Eastern':
      return 'America/New_York';
    case 'Central':
      return 'America/Chicago';
    case 'Mountain':
      return 'America/Denver';
    case 'Pacific':
      return 'America/Los_Angeles';
    case 'Alaska':
      return 'America/Anchorage';
    case 'Hawaii':
      return 'Pacific/Honolulu';
    default:
      return '';
  }
}
