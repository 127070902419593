import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { AnalyticsCard, AnalyticsContent } from '~/ui/components/analytics';
import { BaseCard, CardSize, type CommonCardProps } from '~/ui/components/cards/base';
import { getInternalLinkFromFragment } from '~/ui/systems/internalLink';

import { GrantStoryCard_GrantActivityArticleFragment } from './query';

export interface GrantStoryCardProps extends CommonCardProps {
  data: FragmentType<typeof GrantStoryCard_GrantActivityArticleFragment>;
  size?: CardSize;
}

export function GrantStoryCard({
  data,
  module,
  size = CardSize.DEFAULT,
  ...props
}: GrantStoryCardProps) {
  const grantStory = getFragmentData(GrantStoryCard_GrantActivityArticleFragment, data);

  return (
    <BaseCard
      eyebrow={grantStory.granteeNameOverride ?? grantStory.grant?.organization?.name ?? undefined}
      title={grantStory.title ?? ''}
      image={grantStory.leadImage ?? undefined}
      cta="Read the grant story"
      href={getInternalLinkFromFragment(grantStory)}
      size={size}
      event={{
        cardType: AnalyticsCard.ARTICLE,
        cardContentType: AnalyticsContent.GRANT_STORY,
        cardModule: module,
      }}
      {...props}
    />
  );
}
