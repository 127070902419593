import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { ContentType } from '~/contentful/model/contentType';
import { AnalyticsCard, AnalyticsContent } from '~/ui/components/analytics';
import { BaseCard, CardSize, type CommonCardProps } from '~/ui/components/cards/base';
import { VoicesCard } from '~/ui/components/cards/voices';
import { ArticleCard_ArticleFragment } from '~/ui/modules/cards/article/query';
import { getInternalLink, getInternalLinkFromFragment } from '~/ui/systems/internalLink';
import { formatDate } from '~/ui/utils/formatDate';
import { isInternalLink } from '~/v1/utils/isInternalLink';

export interface ArticleCardProps extends CommonCardProps {
  data: FragmentType<typeof ArticleCard_ArticleFragment>;
  /**
   * Allows specifying a smaller card size (e.g. for carousels)
   * @default {@link CardSize CardSize.LARGE}
   */
  size?: CardSize;
}

export function ArticleCard({ data, size = CardSize.DEFAULT, module, ...props }: ArticleCardProps) {
  const article = getFragmentData(ArticleCard_ArticleFragment, data);

  switch (article.type) {
    case 'Generic':
      return (
        <BaseCard
          eyebrow={article.eyebrow ?? ''}
          title={article.title ?? ''}
          cta="Read the article"
          href={getInternalLinkFromFragment(article)}
          image={article.heroImage ?? undefined}
          size={size}
          event={{
            cardModule: module,
            cardContentType: AnalyticsContent.ARTICLE,
            cardType: AnalyticsCard.ARTICLE,
          }}
          {...props}
        />
      );

    case 'Mellon news':
    case 'Grantee news':
      const href = getInternalLinkFromFragment(article);
      return (
        <BaseCard
          logo={article.publisherLogo ?? undefined}
          eyebrow={formatDate(article.date)}
          title={article.title ?? ''}
          cta="Read the news"
          href={href}
          event={{
            cardModule: module,
            cardType: AnalyticsCard.NEWS,
            cardContentType: isInternalLink(href)
              ? AnalyticsContent.INTERNAL_NEWS
              : AnalyticsContent.EXTERNAL_NEWS,
          }}
          size={size}
          {...props}
        />
      );

    case 'Voices':
      const internalAuthor = article.person
        ? {
            name: article.person.name ?? '',
            title: article.person.jobTitle ?? '',
            organization: article.person.departmentOrGrantMakingArea?.name ?? '',
            image: article.person.image ?? undefined,
          }
        : undefined;
      const externalAuthor = article.externalPerson
        ? {
            name: article.externalPerson.name ?? '',
            title: article.externalPerson.jobTitle ?? '',
            organization: article.externalPerson.organization ?? '',
            image: article.externalPerson.image ?? undefined,
          }
        : undefined;
      return (
        <VoicesCard
          title={article.title ?? ''}
          href={getInternalLinkFromFragment(article)}
          isExternal={!internalAuthor}
          author={internalAuthor || externalAuthor}
          {...props}
        />
      );

    case 'Past event':
      return (
        <BaseCard
          eyebrow="Event"
          title={article.title ?? ''}
          image={article.heroImage ?? undefined}
          cta="Watch event"
          href={getInternalLinkFromFragment(article)}
          size={size}
          secondaryCta="View all events"
          secondaryHref={getInternalLink(ContentType.EVENTS_LANDING_PAGE)}
          event={{
            cardContentType: AnalyticsContent.PAST_EVENT,
            cardType: AnalyticsCard.EVENT,
            cardModule: module,
          }}
        />
      );
  }
}
